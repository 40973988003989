import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalHapus: false,
        capaian: {
            capaian_id: '',
            capaian_nama: '',
            capaian_ket: '',
            capaian_file: '',
            capaian_created_at: '',
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalHapus = value
        },
        viewModal(state, value) {
            state.capaian = value
        }
    }
})
